/**
 * We'll register a HTTP interceptor to attach the "CSRF" header to each of
 * the outgoing requests issued by this application. The CSRF middleware
 * included with Laravel will automatically verify the header's value.
 */
(function () {
    var token = document.head.querySelector('meta[name="csrf-token"]');

    if (token) {
        var op = XMLHttpRequest.prototype.open;
        XMLHttpRequest.prototype.open = function () {
            var resp = op.apply(this, arguments);
            this.setRequestHeader('X-CSRF-Token', token.content);
            return resp;
        };
    } else {
        console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
    }
}());

$(window).on('load resize', function () {
    var $dropdown = $('.navbar .dropdown');
    var $dropdownToggle = $('.dropdown-toggle');
    var $dropdownMenu = $('.dropdown-menu');
    var showClass = 'show';

    if (this.matchMedia('(min-width: 992px)').matches) {
        $dropdown.hover(
            function () {
                const $this = $(this);
                $this.addClass(showClass);
                $this.find($dropdownToggle).attr('aria-expanded', 'true');
                $this.find($dropdownMenu).addClass(showClass);
            },
            function () {
                const $this = $(this);
                $this.removeClass(showClass);
                $this.find($dropdownToggle).attr('aria-expanded', 'false');
                $this.find($dropdownMenu).removeClass(showClass);
            }
        );
    } else {
        $dropdown.off('mouseenter mouseleave');
    }
});

$(document).ready(function () {

    AOS.init();

    // This function sets the show class on the parent main-navbar
    // Needed for border radius styles
    $('#navbar-toggler').on('click', function (e) {
        e.preventDefault();

        var main_navbar = $('#main-navbar');

        if (main_navbar.hasClass('show')) {
            main_navbar.removeClass('show')
        } else {
            main_navbar.addClass('show');
        }
    });

    /**
     * Attachment Gallery
     */
    $('[data-circle-progress]').each(function () {
        var _this = this;
        var id = $(_this).data('circle-progress');
        var value = $(_this).data('circle-progress-value');
        var render_value = $(_this).data('circle-progress-render') || false;
        var radius = $(_this).data('circle-progress-radius') || 30;

        Circles.create({
            id: id,
            radius: radius,
            value: value,
            maxValue: 100,
            width: 8,
            text: function (value) {
                return render_value ? value + '%' : null;
            },
            colors: ['#EAEAEA', '#19AEF7'],
            duration: 400,
            wrpClass: 'circles-wrp',
            textClass: 'circles-text',
            valueStrokeClass: 'circles-valueStroke',
            maxValueStrokeClass: 'circles-maxValueStroke',
            styleWrapper: true,
            styleText: true
        });
    });

    $('[data-map]').each(function () {
        var map_elem = this;
        var map_params = $(this).data('map');

        generateSingleLocationMap(map_elem, map_params);
    });

    $('[data-play-youtube]').click(function (e) {
        e.preventDefault();

        var embed_url = $(this).data('play-youtube');
        var video_wrapper = $(this).parent('.video-wrapper');

        video_wrapper.html('<iframe src="' + embed_url + '" title="YouTube video" allowfullscreen></iframe>');
    });
});


// Select2 hehe
$.fn.select2.defaults.set('theme', 'bootstrap-5');
